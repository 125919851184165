<template style="background-color: white">
  <div id="app-question-add" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="question" :rules="rules"  label-width="100px">
      <major-form-item ref="majorFormItem"></major-form-item>
      <knowledge-form-item ref="knowledgeFormItem"></knowledge-form-item>
      <el-form-item label="法条">
        <select-tree-law ref="lawTree"></select-tree-law>
      </el-form-item>
      <source-form-item ref="sourceFormItem" @parentEvent="getSourceId" :model="question.sourceId" :parent="this" prop="sourceId" ></source-form-item>
      <el-form-item label="难易度">
        <select-difficult ref="selectDifficult" @parentEvent="getDifficultId"></select-difficult>
      </el-form-item>
      <el-form-item label="题干" prop="stem">
        <el-input type="textarea" :rows="4" v-model="question.stem" placeholder="空白处使用下划线"></el-input>
      </el-form-item>
      <el-form-item label="答案">
        <el-input type="textarea" :rows="4" v-model="question.answer" placeholder="多个词之间用英文分号隔开"></el-input>
      </el-form-item>
      <el-form-item label="文字解析" prop="analysisText">
        <el-input type="textarea" :rows="4" v-model="question.analysisText" placeholder = "请输入"></el-input>
      </el-form-item>
      <el-form-item label="视频解析" prop="analysisVideo">
        <el-input type="number" v-model="question.analysisVideo" placeholder="百家云视频ID"></el-input>
      </el-form-item>
      <el-form-item label="考点" prop="examPoint">
        <el-input type="text" v-model="question.examPoint" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {setBodyEvent} from "../../../../public/question/js/question-vue";
import {getLocalProjectId, request, TipEnum, UrlEnum,QuestionTypeEnum} from "../../../../public/js/common-vue";
import KnowledgeFormItem from "@/components/KnowledgeFormItem";
import SelectTreeLaw from "@/components/SelectTreeLaw";
import SourceFormItem from "@/components/SourceFormItem";
import SelectDifficult from "@/components/SelectDifficult";
import MajorFormItem from "@/components/MajorFormItem";
import Loading from "@/components/Loading";
export default {
  name: "Add",
  data(){
    return{
      question:{
        difficultId:1,
        typeId:QuestionTypeEnum.TIAN_KONG,
        stem:'',
        answer:'',
        analysisText:'',
        analysisVideo:0,
        scoreWords:[],
        subjectId:request("subjectId"),
        options:[],
        parentId:0,
        projectId:getLocalProjectId(),
        knowledgeIds:[],
        lawIds:[],
        sourceId:'',
        examPoint:'',
        majorIds:[],
      },
      loading: false,
      rules:{
        sourceId :[{required: true, message:'请输入', trigger:'blur'}],
        stem :[{required: true, message:'请输入', trigger:'blur'}],
        analysisText: [{required:true,message:'请输入',trigger:'blur'}],
        analysisVideo: [{required:true,message:'请输入',trigger:'blur'}]
      },
    }
  },
  components: {Loading, KnowledgeFormItem,SelectTreeLaw,SourceFormItem,SelectDifficult,MajorFormItem},
  methods:{
    getDifficultId(data){
      this.question.difficultId = data;
      console.log(data)
    },
    submit: function (formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.question.knowledgeIds = this.$refs.knowledgeFormItem.getCheckedIds();
          this.question.lawIds = this.$refs.lawTree.getCheckedIds();
          this.question.majorIds = this.$refs.majorFormItem.getCheckedIds();
          this.$http({
            method: "post",
            url: UrlEnum.QUESTION,
            data: this.question,
          })
              .then((res) => {
                this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
                setTimeout(function () {
                  parent.layui.table.reload('tableData'); //重载表格
                  parent.layer.close(index); //再执行关闭
                }, 800);
              })
              .catch((res) => {
                this.loading = false;
                if(res.response != null){
                  const data = res.response.data;
                  this.showMsgError(data.message);
                }
              });
        }
      })
    },
    getSourceId(data){
      this.question.sourceId = data;
      console.log(data);
    },
  },
  mounted() {
    setBodyEvent(this);
    this.$refs.lawTree.initData(null,null);
    this.$refs.knowledgeFormItem.initData(null,this.$refs.knowledgeFormItem.getSessionKnowledges(),request("subjectId"));
    this.$refs.sourceFormItem.initData();
    this.$refs.selectDifficult.initData(null,1);
    this.$refs.majorFormItem.initData(null);
  },
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
</style>
